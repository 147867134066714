import React from "react"
import Layout from "../components/Layout"
import ActionButtons from "../components/ActionButtons"
import SEO from "../components/SEO"

const Contact = () => {
  return (
    <Layout>
      <SEO title="Contact" />
      <main className="page">
        <section className="contact-page">
          <article className="contact-info">
            <h3>Want To Get In Touch?</h3>
            <h4><a href = "mailto: hello@sophia-ux.com">hello@sophia-ux.com</a></h4>
            <ActionButtons />
          </article>
          <article>
            <form
              className="form contact-form"
              action="https://formspree.io/f/xgedrqde"
              method="POST"
            >
              <div className="form-row">
                <label htmlFor="name">your name</label>
                <input type="text" name="name" id="name" />
              </div>
              <div className="form-row">
                <label htmlFor="email">your email</label>
                <input type="text" name="email" id="email" />
              </div>
              <div className="form-row">
                <label htmlFor="message">message</label>
                <textarea
                  name="message"
                  id="message"
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
              <button type="submit" className="btn block">
                submit
              </button>
            </form>
          </article>
        </section>
      </main>
    </Layout>
  )
}
export default Contact
