import React from "react"
import { BsDownload, BsLinkedin } from "react-icons/bs"

const ActionButtons = () => {
  return (
    <div className="hero-buttons">
      <a href="https://drive.google.com/file/d/1OSksRLZKeySAl6ACINtZsIOoEPcmYbam/view?usp=sharing">
        <button type="submit" className="btn with-icon">
          <div>
            <BsDownload />
          </div>
          <div>Resume</div>
        </button>
      </a>
      {/* <button type="submit" className="btn with-icon">
        <div>
          <BsDownload />
        </div>
        <div>Portfolio</div>
      </button> */}
      <a href="https://www.linkedin.com/in/sophia-m%C3%BCller-4b808523b/">
        <button type="submit" className="btn with-icon">
          <div>
            <BsLinkedin />
          </div>
          <div>LinkedIn</div>
        </button>
      </a>
    </div>
  )
}

export default ActionButtons
